import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { EXPENSES } from "../../GraphQL/Query";
import { ADDEXPENSE, DELETEEXPENSE } from "../../GraphQL/Mutation";
import { Alert } from "../StaticComponents/Alert";
import {
	Button,
	Input,
	Table,
	Space,
	Tag,
	InputNumber,
	Modal,
	Popconfirm,
} from "antd";
import {
	PlusOutlined,
	PrinterOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife, faWallet } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
export function Expenses() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useApolloClient();
	const [expenses, setExpenses] = useState([]);
	const [searching, setSearching] = useState("");
	const [newExpense, setNewExpense] = useState(false);
	const [showDark, setShowDark] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [userId, setUserId] = useState("");
	const [total, setTotal] = useState(false);
	const [page, setPage] = useState(1);
	const [alert, setAlert] = useState({
		alertStatus: "",
		action: "",
	});
	if (location.pathname == "/expenses") {
		location.pathname = "مصارف";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		setUserId(JSON.parse(activeUser)?.login?._id);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadExpenses();
	}, [referesh]);
	// Function to calculate the total amount
	const calculateTotalAmount = (expenses) => {
		return expenses.reduce((total, expense) => total + expense.amount, 0);
	};
	const totalAmount = calculateTotalAmount(expenses);
	const columns = [
		{
			title: "ردیف",
			key: "index",
			render: (value, item, index) => (page - 1) * 7 + index + 1,
			align: "center",
		},
		{
			title: "شماره",
			dataIndex: "consumeNumber",
			key: "consumeNumber",
			render: (consumeNumber, expense) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{expense.consumeNumber}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نام",
			dataIndex: "name",
			filteredValue: [searching],
			onFilter: (value, record) => {
				return String(record.name)
					.toLocaleLowerCase()
					.includes(value.toLocaleLowerCase());
			},
			render: (name, expense) => expense.name,
			key: "name",
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			render: (amount, expense) => (
				<Tag
					color="error"
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{expense.amount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "توضیحات",
			dataIndex: "description",
			key: "description",
			render: (description, expense) => expense.description,
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			render: (description, expense) => expense.date,
			align: "center",
		},
		{
			title: userRole === "Admin" ? "عملیات" : null,
			dataIndex: "action",
			key: "action",
			render: (record, expense) => {
				return userRole === "Admin" ? (
					<Space>
						<Popconfirm
							title="آیا مطمین هستید میخواهید حذف نمایید؟"
							onConfirm={() => handleDeleteExpense(expense._id)}
							okText="بله"
							cancelText="انصراف"
							placement="topLeft">
							<DeleteOutlined />
						</Popconfirm>
					</Space>
				) : null;
			},
			align: "center",
		},
	];
	const loadExpenses = async () => {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: EXPENSES,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setExpenses([...res.data.getConsumes]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	};
	function goToPrintExpenses() {
		navigate("/printExpenses");
	}
	async function addNewExpense(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: ADDEXPENSE,
				variables: {
					name: e.target.name.value,
					amount: Number(e.target.amount.value),
					date: e.target.inputDate.value,
					description: e.target.description.value,
					userId: userId,
				},
			});
			if (res) {
				setNewExpense(!newExpense);
				setReferesh(!referesh);
				setAlert({
					action: "success",
					alertStatus: "create",
				});
				setShowDark(!showDark);
			}
			setTimeout(() => {
				setAlert({
					action: "",
					alertStatus: "",
				});
			}, 3000);
		} catch (error) {
			console.log(error);
			setAlert({
				action: "",
				alertStatus: "",
			});
		}
	}
	async function handleDeleteExpense(expenseId) {
		try {
			const res = await client.mutate({
				mutation: DELETEEXPENSE,
				variables: {
					id: expenseId,
				},
			});
			if (res) {
				setReferesh(!referesh);
				setAlert({
					action: "success",
					alertStatus: "delete",
				});
			}
			setTimeout(() => {
				setAlert({
					action: "",
					alertStatus: "",
				});
			}, 3000);
		} catch (err) {
			console.log(err);
			setAlert({
				action: "",
				alertStatus: "",
			});
		}
	}
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<div className="content">
				{alert.action != "" && (
					<Alert action={alert.action} alertStatus={alert.alertStatus} />
				)}
				<div className="file-container">
					<div className="file-header">
						<Input.Search
							placeholder="جستجوی مصرف"
							allowClear
							size="large"
							onChange={(e) => {
								setSearching(e.target.value);
							}}
							style={{
								width: 250,
								direction: "ltr",
								textAlign: "right",
							}}
						/>
						{userRole === "Admin" && (
							<Button
								type="primary"
								size="large"
								icon={<PlusOutlined />}
								onClick={() => {
									setNewExpense(!newExpense);
									setShowDark(!showDark);
								}}
								className="btn">
								ثبت مصرف
							</Button>
						)}
						<Button
							type="primary"
							size="large"
							icon={<PrinterOutlined />}
							onClick={goToPrintExpenses}
							className="btn">
							چاپ
						</Button>
						{userRole === "Admin" && (
							<FontAwesomeIcon
								icon={faWallet}
								style={{ fontSize: 40, color: " #1677ff", cursor: "pointer" }}
								onClick={() => {
									setTotal(!total);
								}}
							/>
						)}
						{userRole === "Admin" &&
							(total ? (
								<Tag
									bordered={false}
									color="success"
									style={{
										fontSize: 20,
										fontFamily: "B-NAZANIN",
										width: "200px",
										height: "40px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
										color: "#777",
									}}>
									{
										<Space>
											{totalAmount === undefined
												? "در حال بررسی ..."
												: totalAmount + " " + "افغانی"}
										</Space>
									}
								</Tag>
							) : (
								<Tag
									bordered={false}
									color="success"
									style={{
										fontSize: 20,
										fontFamily: "B-NAZANIN",
										width: "200px",
										height: "40px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
									}}>
									{
										<Space>
											<FontAwesomeIcon
												icon={faStarOfLife}
												style={{ fontSize: 15, color: "#777" }}
											/>
											<FontAwesomeIcon
												icon={faStarOfLife}
												style={{ fontSize: 15, color: "#777" }}
											/>
											<FontAwesomeIcon
												icon={faStarOfLife}
												style={{ fontSize: 15, color: "#777" }}
											/>
											<FontAwesomeIcon
												icon={faStarOfLife}
												style={{ fontSize: 15, color: "#777" }}
											/>
											<FontAwesomeIcon
												icon={faStarOfLife}
												style={{ fontSize: 15, color: "#777" }}
											/>
										</Space>
									}
								</Tag>
							))}
					</div>
					<div className="file-content">
						{newExpense && (
							<Modal
								title="ثبت مصرف"
								open={newExpense}
								onCancel={() => {
									setNewExpense(!newExpense);
								}}
								footer={null}
								style={{ direction: "rtl" }}
								centered>
								<form onSubmit={addNewExpense}>
									<Space direction="vertical">
										<Input
											id="name"
											name="name"
											size="large"
											placeholder="نام مصرف"
										/>
										<InputNumber
											placeholder="مبلغ مصرف"
											id="amount"
											name="amount"
											size="large"
											min={0}
											style={{
												width: "100%",
											}}
										/>
										<Space className="inputDate-container">
											<DatePicker
												calendar={persian}
												locale={persian_fa}
												months={MONTHS}
												calendarPosition="top-right"
												format="YYYY-MM-DD"
												inputClass="inputDate"
												value={today}
												id="inputDate"
												name="inputDate"
											/>
										</Space>
										<Input
											placeholder="توضیحات"
											id="description"
											name="description"
											size="large"
										/>
										<Space
											style={{
												width: "100%",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Button
												type="primary"
												htmlType="submit"
												size="large"
												icon={<PlusOutlined />}
												className="btn">
												ثبت مصرف
											</Button>
										</Space>
									</Space>
								</form>
							</Modal>
						)}
						<Table
							dataSource={expenses}
							columns={columns}
							loading={isLoader}
							locale={{
								emptyText: <img src="emptyData.png" alt="empty" width={200} />,
							}}
							pagination={{
								pageSize: 7,
								showSizeChanger: false,
								onChange(current) {
									setPage(current);
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
