import { Header } from "../StaticComponents/Header";
import { SideBar } from "../StaticComponents/SideBar";
import "../../Styles/StaticComponents/Global.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../StaticComponents/Loader";
import { Alert } from "../StaticComponents/Alert";
import { useApolloClient } from "@apollo/client";
import { FACTORS } from "../../GraphQL/Query";
import { DELETEFACTOR, UPDATEFACTOR } from "../../GraphQL/Mutation";
import {
	Button,
	Dropdown,
	Menu,
	Input,
	Table,
	Space,
	Tag,
	Popconfirm,
	Modal,
} from "antd";
import {
	PlusOutlined,
	AlignRightOutlined,
	CaretDownOutlined,
	PrinterOutlined,
	EditOutlined,
	DeleteOutlined,
	EllipsisOutlined,
	SelectOutlined,
} from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { MONTHS } from "../../Constant/const";
export function FactorList() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const client = useApolloClient();
	const navigate = useNavigate();
	const [factors, setFactors] = useState([]);
	const [selectedFactor, setSelectedFacor] = useState();
	const navigat = useNavigate();
	const location = useLocation();
	const [searching, setSearching] = useState("");
	const [isLoader, setIsLoader] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [page, setPage] = useState(1);
	const [alert, setAlert] = useState({
		alertStatus: "",
		action: "",
	});
	if (location.pathname == "/factorList") {
		location.pathname = "لیست فاکتورها";
	}
	useEffect(() => {
		const activeUser = window.localStorage.getItem("activeUser");
		setUserRole(JSON.parse(activeUser)?.login?.role);
		if (!JSON.parse(activeUser)) {
			navigate("/login");
		}
		loadFactors();
	}, [referesh]);
	async function loadFactors() {
		try {
			setIsLoader(true);
			const res = await client.query({
				query: FACTORS,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setFactors([...res.data.getFactors]);
				setIsLoader(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoader(false);
		}
	}
	async function handleDeleteFactor(factorId) {
		try {
			const res = await client.mutate({
				mutation: DELETEFACTOR,
				variables: {
					id: factorId,
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setReferesh(!referesh);
				setAlert({
					action: "success",
					alertStatus: "delete",
				});
			}
			setTimeout(() => {
				setAlert({
					action: "",
					alertStatus: "",
				});
			}, 3000);
		} catch (err) {
			console.log(err);
			setAlert({
				action: "",
				alertStatus: "",
			});
		}
		// const filteredRows = customers.filter(item=> item.customerId !=customerId)
		// deleteCustomer(filteredRows)
	}
	// function for editFactor
	function handleEditFactor(factorId) {
		const filteredFactor = factors.filter((item) => item._id === factorId);
		setSelectedFacor(filteredFactor[0]);
		setIsEditing(!isEditing);
		console.log(filteredFactor[0]);
	}
	async function handleUpdateFactor(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: UPDATEFACTOR,
				variables: {
					factorId: selectedFactor._id,
					date: e.target.inputDate.value.replace(
						/[\u0660-\u0669\u06f0-\u06f9]/g,
						function (a) {
							return a.charCodeAt(0) & 0xf;
						}
					),
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setIsEditing(!isEditing);
				setReferesh(!referesh);
				setAlert({
					action: "success",
					alertStatus: "edit",
				});
			}
			setTimeout(() => {
				setAlert({
					action: "",
					alertStatus: "",
				});
			}, 3000);
		} catch (err) {
			console.log(err);
			setAlert({
				action: "",
				alertStatus: "",
			});
		}
	}
	const filterPayment = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => {
						setSearching("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => {
						setSearching("Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نقده
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						setSearching("No_Cash");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					نسیه
				</Button>
			),
		},
	];
	const menu1 = (
		<Menu>
			{filterPayment.map((payment) => (
				<Menu.Item key={payment.key}>{payment.label}</Menu.Item>
			))}
		</Menu>
	);
	const filterFactor = [
		{
			key: "1",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => {
						setSearching("");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					همه
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					paddingBlock="0"
					onClick={() => {
						setSearching("Buy");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					خرید
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button
					type="link"
					block="true"
					icon={<PrinterOutlined />}
					onClick={() => {
						setSearching("Sell");
					}}
					style={{ color: "rgba(0, 0, 0, 0.88)", direction: "rtl" }}>
					فروش
				</Button>
			),
		},
	];
	const menu2 = (
		<Menu>
			{filterFactor.map((factor) => (
				<Menu.Item key={factor.key}>{factor.label}</Menu.Item>
			))}
		</Menu>
	);
	const columns = [
		{
			title: "ردیف",
			key: "index",
			render: (value, item, index) => (page - 1) * 7 + index + 1,
			align: "center",
		},
		{
			title: "نام",
			dataIndex: ["factor", "fullName"],
			render: (fullName, factor) => factor.customer?.fullName,
			key: "fullName",
			filteredValue: [searching],
			onFilter: (value, factor) => {
				return (
					String(factor.customer?.fullName)
						.toLocaleLowerCase()
						.includes(value.toLocaleLowerCase()) ||
					String(factor.factorType).includes(value) ||
					String(factor.paymentType).startsWith(value)
				);
			},
			align: "center",
		},
		{
			title: "شماره",
			dataIndex: "factorNumber",
			key: "factorNumber",
			render: (factorNumber, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.buyFactorNumber || factor.sellFactorNumber}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نوعیت بل",
			dataIndex: "factorType",
			key: "factorType",
			render: (factorType, factor) => (
				<Tag
					bordered={false}
					color={factor.factorType === "Sell" ? "success" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.factorType === "Sell" ? "فروش" : "خرید"}
				</Tag>
			),
			align: "center",
		},
		{
			title: "نوعیت پرداخت",
			dataIndex: "paymentType",
			key: "paymentType",
			render: (paymentType, factor) => (
				<Tag
					bordered={false}
					color={factor.paymentType === "Cash" ? "success" : "error"}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.paymentType === "Cash" ? "نقده" : "نسیه"}
				</Tag>
			),
			align: "center",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			render: (amount, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.amount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تخفیف",
			dataIndex: "discount",
			key: "discount",
			render: (discount, factor) => (
				<Tag
					bordered={false}
					style={{
						fontSize: 15,
						fontFamily: "B-NAZANIN",
					}}>
					{factor.discount}
				</Tag>
			),
			align: "center",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
			render: (date, factor) => factor.date,
			align: "center",
		},
		{
			title: userRole === "Admin" ? "عملیات" : null,
			dataIndex: "action",
			key: "action",
			render: (record, factor) => {
				return userRole === "Admin" ? (
					<Space className="action-space">
						<PrinterOutlined
							onClick={() =>
								navigate("/printFactor", {
									state: { selectedFactor: factor._id },
								})
							}
						/>
						<EditOutlined onClick={() => handleEditFactor(factor._id)} />
						<Popconfirm
							title="آیا مطمین هستید میخواهید حذف نمایید؟"
							onConfirm={() => handleDeleteFactor(factor._id)}
							okText="بله"
							cancelText="انصراف"
							placement="topLeft">
							<DeleteOutlined />
						</Popconfirm>
					</Space>
				) : null;
			},
			align: "center",
		},
	];
	return (
		<div className="container">
			<Header address={location.pathname} />
			<SideBar />
			<div className="content">
				{alert.action != "" && (
					<Alert action={alert.action} alertStatus={alert.alertStatus} />
				)}
				{
					<Modal
						title="ویرایش فاکتور"
						open={isEditing}
						onCancel={() => {
							setIsEditing(!isEditing);
						}}
						footer={null}
						style={{ direction: "rtl" }}
						centered>
						<form onSubmit={handleUpdateFactor}>
							<Space direction="vertical">
								<Space className="inputDate-container">
									<DatePicker
										calendar={persian}
										locale={persian_fa}
										months={MONTHS}
										calendarPosition="top-right"
										format="YYYY-MM-DD"
										inputClass="inputDate"
										value={selectedFactor?.date}
										id="inputDate"
										name="inputDate"
									/>
								</Space>
								<Space
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										icon={<PlusOutlined />}
										className="btn">
										ویرایش فاکتور
									</Button>
								</Space>
							</Space>
						</form>
					</Modal>
				}
				<div className="file-container">
					<div className="file-header">
						<Input.Search
							placeholder="جستجوی مشتری"
							allowClear
							size="large"
							onSearch={(value) => {
								setSearching(value);
							}}
							onChange={(e) => {
								setSearching(e.target.value);
							}}
							style={{
								width: 250,
								direction: "ltr",
								textAlign: "right",
							}}
						/>
						<Dropdown
							overlay={menu1}
							placement="bottom"
							arrow={{ pointAtCenter: true }}
							className="btn">
							<Button type="primary" size="large" icon={<CaretDownOutlined />}>
								نوعیت پرداخت
							</Button>
						</Dropdown>
						<Dropdown
							overlay={menu2}
							placement="bottom"
							arrow={{ pointAtCenter: true }}
							className="btn">
							<Button type="primary" size="large" icon={<CaretDownOutlined />}>
								نوعیت فاکتور
							</Button>
						</Dropdown>
					</div>
					<div className="file-content">
						<Table
							dataSource={factors}
							columns={columns}
							loading={isLoader}
							locale={{
								emptyText: <img src="emptyData.png" alt="empty" width={200} />,
							}}
							pagination={{
								pageSize: 7,
								showSizeChanger: false,
								onChange(current) {
									setPage(current);
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
