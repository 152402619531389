import { gql } from "@apollo/client";

// users
export const USERS = gql`
	query {
		getUsers {
			_id
			firstName
			lastName
			userName
			password
			role
			createdAt
		}
	}
`;
// stacks
export const STACKS = gql`
	query {
		getStacks {
			_id
			name
			type
			address
			createdAt
		}
	}
`;
// remittance
export const REMITTANCE = gql`
	query {
		getRemittances {
			_id
			number
			type
			amount
			customerName
			shopAddress
			via
			description
			date
		}
	}
`;
// employees
export const EMPLOYEES = gql`
	query {
		getEmployees {
			_id
			fullName
			phoneNumber
			job
			contractDate
			workTime
			salary
			createdAt
			balance
		}
	}
`;
// customers
export const CUSTOMERS = gql`
	query {
		getCustomers {
			_id
			fullName
			phoneNumber
			city
			address
			company
			balance
			category
			createdAt
		}
	}
`;
// drugType
export const DRUGTYPES = gql`
	query {
		getDrugTypes {
			_id
			title
			createdAt
		}
	}
`;
// drugs
export const DRUGS = gql`
	query getDrugs($pageNumber: Int, $perPage: Int, $searchItem: String) {
		getDrugs(
			pageNumber: $pageNumber
			perPage: $perPage
			searchItem: $searchItem
		) {
			_id
			name
			drugType {
				_id
				title
				createdAt
			}
			company
			country
			amount
			price
			stack {
				_id
				name
				type
				address
				createdAt
			}
			expDate
			createdAt
		}
	}
`;
// checks
export const CHECKS = gql`
	query {
		getChecks {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
				phoneNumber
				city
				address
				company
				balance
				createdAt
			}
		}
	}
`;
// check
export const CHECK = gql`
	query getCheck($id: ID!) {
		getCheck(id: $id) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
// factors
export const FACTORS = gql`
	query {
		getFactors {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			discount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
// factor
export const FACTOR = gql`
	query getFactor($id: ID!) {
		getFactor(id: $id) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
			items {
				buyFactorNumber
				sellFactorNumber
				drug {
					_id
					name
					drugType {
						_id
						title
					}
					company
				}
				quantity
				price
				total
				description
			}
		}
	}
`;
// lastFactor
export const LASTFACTOR = gql`
	query getLastFactor($factorType: FactorTypeEnum!) {
		getLastFactor(factorType: $factorType) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
			items {
				drug {
					_id
					name
					drugType {
						_id
						title
					}
					company
					country
				}
				quantity
				price
				total
				description
			}
		}
	}
`;
// roznamcha
export const ROZNAMCHA = gql`
	query getRoznamcha($date: DateTime) {
		getRoznamcha(date: $date) {
			_id
			bellNumber
			bellType
			date
			amount
			customer {
				_id
				fullName
			}
			employee {
				_id
				fullName
			}
			user {
				_id
				firstName
				lastName
				userName
			}
			createdAt
		}
	}
`;
// reports
// drugReport
export const REPORTDRUG = gql`
	query reportDrugs(
		$drugType: ID
		$drugName: String
		$drugCompany: String
		$drugCountry: String
		$drugStack: String
		$startAmount: Float
		$endAmount: Float
		$startPrice: Float
		$endPrice: Float
		$startDate: DateTime
		$endDate: DateTime
	) {
		reportDrugs(
			drugType: $drugType
			drugName: $drugName
			drugCompany: $drugCompany
			drugCountry: $drugCountry
			drugStack: $drugStack
			startAmount: $startAmount
			endAmount: $endAmount
			startPrice: $startPrice
			endPrice: $endPrice
			startDate: $startDate
			endDate: $endDate
		) {
			_id
			name
			drugType {
				_id
				title
			}
			company
			country
			amount
			price
			stack {
				_id
				name
				type
			}
			expDate
			createdAt
		}
	}
`;
export const REPORTCUSTOMER = gql`
	query reportCustomers(
		$fullName: String
		$balanceStatus: BalanceStatusEnum
		$city: String
		$address: String
		$startBalance: Float
		$endBalance: Float
		$category: String
	) {
		reportCustomers(
			fullName: $fullName
			balanceStatus: $balanceStatus
			city: $city
			address: $address
			startBalance: $startBalance
			endBalance: $endBalance
			category: $category
		) {
			_id
			fullName
			phoneNumber
			city
			address
			company
			balance
			category
			createdAt
		}
	}
`;
export const REPORTCHECK = gql`
	query reportChecks(
		$checkType: CheckTypeEnum
		$checkNumber: Int
		$startDate: DateTime
		$endDate: DateTime
		$startAmount: Float
		$endAmount: Float
		$customer: ID
	) {
		reportChecks(
			checkType: $checkType
			checkNumber: $checkNumber
			startDate: $startDate
			endDate: $endDate
			startAmount: $startAmount
			endAmount: $endAmount
			customer: $customer
		) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const REPORTFACTOR = gql`
	query reportFactors(
		$factorType: FactorTypeEnum
		$paymentType: PaymentTypeEnum
		$factorNumber: Int
		$customer: ID
		$drug: ID
		$startDate: DateTime
		$endDate: DateTime
		$startAmount: Float
		$endAmount: Float
	) {
		reportFactors(
			factorType: $factorType
			paymentType: $paymentType
			factorNumber: $factorNumber
			customer: $customer
			drug: $drug
			startDate: $startDate
			endDate: $endDate
			startAmount: $startAmount
			endAmount: $endAmount
		) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const REPOSITORY = gql`
	query getRepository {
		getRepository
	}
`;
export const LASTCHEK = gql`
	query getLastCheck($checkType: CheckTypeEnum!) {
		getLastCheck(checkType: $checkType) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			date
			amount
			description
			customer {
				_id
				fullName
			}
		}
	}
`;
export const STATISTIC = gql`
	query getStatistic {
		getStatistic {
			userCount
			stackCount
			drugCount
			customerCount
			checkCount
			factorCount
		}
	}
`;
export const DRUGDETAILS = gql`
	query getDrugDetails($id: ID) {
		getDrugDetails(id: $id) {
			_id
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
				phoneNumber
				city
				address
				company
				balance
				createdAt
			}
			items {
				buyFactorNumber
				sellFactorNumber
				drug {
					_id
					name
					drugType {
						_id
						title
						createdAt
					}
					company
					country
					amount
					price
					stack {
						_id
						name
						type
						address
						createdAt
					}
					expDate
					createdAt
				}
				quantity
				price
				total
				description
			}
		}
	}
`;
export const SALARIES = gql`
	query getSalaries($employeeId: ID!) {
		getSalaries(employeeId: $employeeId) {
			_id
			employeeId {
				_id
				fullName
			}
			salaryNumber
			amount
			date
			description
			salaryType
		}
	}
`;
export const EXPENSES = gql`
	query getConsumes {
		getConsumes {
			_id
			name
			consumeNumber
			amount
			date
			description
		}
	}
`;
export const CUSTOMERDETAILS = gql`
	query getCustomerDetails($id: ID!) {
		getCustomerDetails(id: $id) {
			_id
			checkInNumber
			checkOutNumber
			checkType
			buyFactorNumber
			sellFactorNumber
			factorType
			paymentType
			date
			amount
			description
			customer {
				_id
				fullName
				balance
			}
			customerBalance
		}
	}
`;
export const PROFILE = gql`
	query getProfile {
		getProfile {
			titleOne
			titleTwo
			owners
			phones
			address
			logo
			barcode
		}
	}
`;
